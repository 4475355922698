<template>
  <div class="flex mt-3 px-6 pt-4 pb-9">
    <div class="border-r border-custom-gray-8 w-1/4 pr-6">
      <h4 class="text-black text-lg font-bold mb-4">
        Saved Search
      </h4>
      <div
        v-if="savedSearches && savedSearches.length > 0"
        class="flex justify-between items-center mb-4"
      >
        <div class="flex">
          <span class="text-black text-sm font-bold mr-3">
            Sort:
          </span>
          <span
            class="underline text-black text-sm mr-3 cursor-pointer"
            @click="handleSavedSearchSort('creationDate,ASC')"
          >
            Oldest
          </span>
          <span
            class="underline text-black text-sm mr-3 cursor-pointer"
            @click="handleSavedSearchSort('creationDate,DESC')"
          >
            Newest
          </span>
        </div>
        <div class="flex">
          <icon-button
            class="mr-3 w-7.5 h-7.5"
            icon="icons/sortAscending.svg"
            active
            active-btn-class="bg-custom-gray-2"
            active-icon-class="text-black"
            @click="handleSavedSearchSort('searchName,ASC')"
          />
          <icon-button
            class="w-7.5 h-7.5"
            icon="icons/sortDescending.svg"
            active
            active-btn-class="bg-custom-gray-2"
            active-icon-class="text-black"
            @click="handleSavedSearchSort('searchName,DESC')"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <div
          v-for="(savedSearch, sIndex) in savedSearches"
          :key="'saved-search' + sIndex"
          class="flex justify-between"
        >
          <div
            class="mr-2"
            @click="loadSavedSearch(savedSearch)"
          >
            <span
              class="italic mr-2 text-xs font-bold"
            >{{ savedSearch.accessMode === ADVANCED_SEARCH_MODE ? 'Ad' : '' }}</span>
            <span
              class="text-primary-red underline cursor-pointer"
              :class="{
                'ml-3.5': savedSearch.accessMode !== ADVANCED_SEARCH_MODE
              }"
            >
              {{ savedSearch.searchName }}
            </span>
          </div>
          <div class="flex">
            <base-button
              variant="btn-link"
              class="mr-3"
              text="Rename"
              @click="handleRenameSearch(savedSearch)"
            />
            <base-button
              variant="btn-link"
              class="text-custom-orange-1"
              text="Delete"
              @click="handleSearchDeletion(savedSearch)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-3/4 pl-6">
      <!-- advance search section starts -->
      <template
        v-if="searchMode === ADVANCED_SEARCH_MODE"
      >
        <div class="flex items-center mb-4">
          <h4 class="text-black text-lg font-bold mr-16">
            Advanced Search
          </h4>
          <div v-if="loadedSavedSearch && loadedSavedSearch.searchName">
            <span class="text-sm text-black font-semibold">Saved Search Name: </span>
            <span class="text-sm text-black">{{ loadedSavedSearch.searchName }}</span>
          </div>
        </div>
        <div class="flex mb-4">
          <icon-button
            class="mr-3 w-7.5 h-7.5"
            icon="icons/plus.svg"
            active
            active-btn-class="bg-custom-green-1 bg-opacity-20"
            active-icon-class="text-custom-green-1"
            @click="addAdvanceSearchFilter()"
          />
          <icon-button
            class="w-7.5 h-7.5"
            icon="icons/x.svg"
            :active="isDeleteFilterBtnActive"
            active-btn-class="bg-primary-red bg-opacity-20"
            active-icon-class="text-primary-red"
            @click="deleteAdvanceSearchFilter()"
          />
        </div>
        <brand-assurance-table
          id="AdvanceSearchFiltersTable"
          v-model="advanceSearchFilterTableData"
          class="advance-search-dropdown"
          :are-columns-sortable="false"
          root-element-class="mb-10"
        >
          <!-- show 1 empty row if table is empty -->
          <template
            v-if="advanceSearchFilterTableData.data.length === 0"
            #customTableBody
          >
            <tr>
              <td class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs">
              &nbsp;
              </td>
              <td
                v-for="(_, tdIndex) in advanceSearchFilterTableData.columns"
                :key="'cell' + tdIndex"
                class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
              &nbsp;
              </td>
            </tr>
          </template>
          <!-- inject dropdown in each cell of search field column -->
          <template
            v-for="(advanceFilter, rowIndex) in advanceSearchFilterTableData.data"
            :key="'cell-slot' + rowIndex + '1'"
            #[generateTableSlotName(rowIndex,1)]
            class="123"
          >
            <base-select
              :model-value="advanceFilter.searchField"
              class="flex items-center text-sm text-left 123"
              placeholder="-- SELECT --"
              dropdown-btn-container-class="w-full static"
              dropdown-container-class="max-w-sm"
              :options="searchFieldOptions"
              show-default-option
              @update:modelValue="handleSearchField($event, advanceSearchFilterTableData.data, rowIndex)"
            />
          </template>
          <!-- inject dropdown in each cell of search condition column -->
          <template
            v-for="(advanceFilter, rowIndex) in advanceSearchFilterTableData.data"
            :key="'cell-slot' + rowIndex + '2'"
            #[generateTableSlotName(rowIndex,2)]
          >
            <base-select
              :model-value="advanceFilter.searchCondition"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              dropdown-btn-container-class="w-full static"
              dropdown-container-class="max-w-sm"
              :options="searchConditionsOptions"
              show-default-option
              @dropdown-opened="searchConditionsOptionsOpened(advanceFilter)"
              @update:modelValue="handleSearchCondition($event, advanceSearchFilterTableData.data, rowIndex)"
            />
          </template>
          <!-- inject input/select dropdown in each cell of search string column -->
          <template
            v-for="(advanceFilter, rowIndex) in advanceSearchFilterTableData.data"
            :key="'cell-slot' + rowIndex + '3'"
            #[generateTableSlotName(rowIndex,3)]
          >
            <base-select
              v-if="areSearchStringsDropdown[rowIndex]"
              v-model="advanceFilter.searchString"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              dropdown-btn-container-class="w-full static"
              dropdown-container-class="max-w-xs"
              show-default-option
              :options="getDistinctArray(searchStringOptions)"
              :disabled="advanceFilter.searchCondition === 'Is not Empty' || advanceFilter.searchCondition === 'Is Empty'"
              @dropdownOpened="fetchSearchStringDropDownValues(advanceFilter)"
            />
            <base-date-picker
              v-else-if="areSearchStringsDropdownWithDateRange[rowIndex]"
              :model-value="advanceFilter.searchString"
              container-class="w-full flex text-sm"
              :disabled="advanceFilter.searchCondition === 'Is not Empty' || advanceFilter.searchCondition === 'Is Empty'"
              label-class="label-w-46"
              is-range
              mode="date"
              :is-today-visible="false"
              :date-formatter="dateFormatter"
              @update:modelValue="handleDateRangeSelection($event, rowIndex)"
            />
            <base-date-picker
              v-else-if="areSearchStringsDropdownWithDate[rowIndex]"
              :model-value="advanceFilter.searchString"
              container-class="w-full flex text-sm"
              :disabled="advanceFilter.searchCondition === 'Is not Empty' || advanceFilter.searchCondition === 'Is Empty'"
              label-class="label-w-46"
              mode="date"
              :date-picker-never-disable="true"
              @update:modelValue="handleDateSelection($event, rowIndex)"
            />
            <base-input
              v-else
              v-model="advanceFilter.searchString"
              type="text"
              :disabled="advanceFilter.searchCondition === 'Is not Empty' || advanceFilter.searchCondition === 'Is Empty'"
              container-class="w-full flex items-center"
            />
          </template>
        </brand-assurance-table>
      </template>
      <!-- advance search section ends -->
      <!-- simple search section starts -->
      <template v-else>
        <div class="flex items-center mb-4">
          <h4 class="text-black text-lg font-bold mr-16">
            Quick Search
          </h4>
          <div v-if="loadedSavedSearch && loadedSavedSearch.searchName">
            <span class="text-sm text-black font-semibold">Saved Search Name: </span>
            <span class="text-sm text-black">{{ loadedSavedSearch.searchName }}</span>
          </div>
        </div>
        <div class="flex items-center mb-4">
          <base-select
            v-model="simpleSearchFilters.searchFields"
            class="w-56 flex items-center text-sm text-left mr-4"
            multi-select
            placeholder="-- SELECT --"
            :disable-selection="simpleSearchFilters.searchFields.length >= 5"
            :options="searchFieldOptions"
            show-default-option
          />
          <template v-if="simpleSearchFilters.searchFields.length > 0">
            <icon-button
              class="mr-3 w-7 h-7"
              icon="icons/trash.svg"
              active
              active-btn-class="bg-custom-orange-1"
              active-icon-class="text-white"
              @click="resetSimpleSearch()"
            />
            <div
              v-for="(simpleSearchField, sIndex) in simpleSearchFilters.searchFields"
              :key="'simple-search-field' + sIndex"
              class="flex items-center py-0.5 px-2 bg-custom-green-1 bg-opacity-25 mr-3 rounded-sm border border-custom-green-1 border-opacity-20"
            >
              <span class="text-custom-green-3 text-sm mr-1">
                {{ simpleSearchField }}
              </span>
              <base-svg
                class="h-3.5 w-3.5 text-custom-green-3 inline-block hover:text-primary-red cursor-pointer align-middle"
                src="icons/cross.svg"
                :svg-attributes="{
                  class: 'h-full w-full'
                }"
                tag="span"
                @click="deleteSimpleSearchField(sIndex)"
              />
            </div>
          </template>
        </div>
        <base-input
          v-model="simpleSearchFilters.searchString"
          type="text"
          placeholder="Enter Search Text Here..."
          container-class="w-full max-w-sm flex items-center mb-4"
        />
      </template>
      <!-- simple search section ends -->
      <div class="flex mb-8">
        <base-button
          variant="btn-primary"
          class="mr-4"
          :is-loading="isSubmissionTableLoading || isTaskLoading"
          text="Apply Search"
          @click="applySearch()"
        />
        <!-- <base-button
          v-if="searchMode === ADVANCED_SEARCH_MODE"
          variant="btn-link"
          class="mr-4"
          text="Modify Search"
          @click="modifyAdvanceSearch()"
        /> -->
        <base-button
          variant="btn-link"
          class="mr-4"
          :text="`${loadedSavedSearch && loadedSavedSearch.searchName ? 'Modify':'Save'} Search` "
          :is-loading="saveSearchLoading"
          @click="handleSaveSearch()"
        />
        <base-button
          v-if="searchMode === ADVANCED_SEARCH_MODE"
          variant="btn-link"
          class="mr-4"
          text="New Advanced Search"
          @click="resetAdvanceSearch()"
        />
        <base-button
          v-if="searchMode === ADVANCED_SEARCH_MODE"
          variant="btn-link"
          class="mr-4"
          text="Go To Simple Search"
          @click="switchSearch(SIMPLE_SEARCH_MODE)"
        />
        <base-button
          v-if="searchMode === SIMPLE_SEARCH_MODE"
          variant="btn-link"
          text="Go To Advance Search"
          @click="switchSearch(ADVANCED_SEARCH_MODE)"
        />
      </div>
      <div
        v-if="(submissionsData.tableData.data && submissionsData.tableData.data.length > 0) || isSubmissionTableLoading"
        class="border-t border-custom-gray-8 min-h-40 py-6"
      >
        <div class="flex justify-end mb-4">
          <display-limit-selector
            v-model="submissionsData.limit"
            class="mr-4"
            :options="limitOptions"
            @click="handlePageUpdate(1)"
          />
          <base-pagination
            :model-value="submissionsData.page"
            :total-pages="submissionsData.pageCount"
            :total-count="submissionsData.totalCount"
            :limit="submissionsData.limit"
            @update:modelValue="handlePageUpdate($event, true)"
          />
        </div>
        <div class="flex mb-4">
          <icon-button
            title="Refresh"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/arrowsClockwise.svg"
            active
            active-btn-class="bg-custom-green-1 bg-opacity-20"
            active-icon-class="text-custom-green-1"
            :is-loading="isSubmissionTableLoading"
            @click="handleRefresh()"
          />
          <icon-button
            title="Open Task"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/folderNotchOpen.svg"
            :active="openTaskButtonActive"
            active-btn-class="bg-blue-600 bg-opacity-20"
            active-icon-class="text-blue-600"
            @click="openSubmissions()"
          />
          <icon-button
            title="Claim Task(s)"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/downloadSimple.svg"
            :active="claimTaskButtonActive"
            :is-loading="isSubmissionTableLoading || isTaskLoading"
            active-btn-class="bg-indigo-600 bg-opacity-20"
            active-icon-class="text-indigo-600"
            @click="taskActions('claim')"
          />
          <icon-button
            title="Unclaim Task(s)"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/thumbsDown.svg"
            :active="unclaimTaskButtonActive"
            :is-loading="isSubmissionTableLoading || isTaskLoading"
            active-btn-class="bg-custom-orange-1 bg-opacity-20"
            active-icon-class="text-custom-orange-1"
            @click="taskActions('unclaim')"
          />
          <icon-button
            v-if="!isUserRestricted"
            title="Assign Task(s)"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/share.svg"
            :active="assignButtonActive"
            :is-loading="isSubmissionTableLoading || isTaskLoading"
            active-btn-class="bg-emerald-600 bg-opacity-20"
            active-icon-class="text-emerald-600"
            @click="openAssignTaskModal('assign')"
          />
          <icon-button
            v-if="!isUserRestricted"
            title="Re-assign Task(s)"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/arrowBendDoubleUpRight.svg"
            :active="reAssignButtonActive"
            :is-loading="isSubmissionTableLoading || isTaskLoading"
            active-btn-class="bg-cyan-700 bg-opacity-20"
            active-icon-class="text-cyan-700"
            @click="openAssignTaskModal('reassign')"
          />
          <icon-button
            active
            title="Export to excel"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/fileX.svg"
            active-icon-class="text-black"
            active-btn-class="bg-black bg-opacity-20"
            @click="exportExcel()"
          />
          <div
            v-if="isPrintingReceipts"
            class="w-7.5 h-7.5"
          >
            <base-svg
              class="w-4 h-4 m-auto py-1 text-primary-red inline-block"
              src="icons/circleSpinner.svg"
              tag="span"
            />
          </div>
          <icon-button
            v-else
            title="Print Receipt"
            class="mr-3 w-7.5 h-7.5"
            icon="icons/printer.svg"
            :active="printReceiptsButtonActive"
            active-btn-class="bg-teal-700 bg-opacity-20"
            active-icon-class="text-teal-700"
            @click="printReceipts()"
          />

          <base-select
            v-if="submissionsData.tableData.data && submissionsData.tableData.data.length > 0"
            :model-value="submissionFilters.columnChooser"
            multi-select
            class="text-sm max-w-full w-64 text-left"
            placeholder="Choose columns to display"
            text-value-override="Choose columns to display"
            :options="submissionColumnChooserOptionsArray"
            @update:model-value="filterColumnChooser($event, submissionFilters.columnChooser)"
          />
        </div>
        <div
          v-if="isSubmissionTableLoading"
          class="mt-40 text-center"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
        </div>
        <brand-assurance-table
          v-else-if="submissionsData.tableData.data.length > 0"
          id="AdvanceSearchSubmissionsTable"
          v-model="submissionsData.tableData"
          root-element-class="mb-20"
          are-columns-resizable
          are-columns-interchangable
          use-custom-sort-logic
          :are-columns-sortable="!isSubmissionTableSorting"
          @columnDragged="savePreferences()"
          @column-resized="colResized()"
          @columnSorted="sortSubmissionTable($event)"
        >
          <!-- inject link in submission ID column -->
          <template
            v-for="(submissionIdCellSlotName, submissionIndex) in submissionIdCellSlotNames"
            :key="submissionIdCellSlotName"
            #[submissionIdCellSlotName]
          >
            <span
              class="underline cursor-pointer"
              @click="openSubmissions({
                submissionId: submissionsData.tableData.data[submissionIndex].submissionId,
                licenseeName: submissionsData.tableData.data[submissionIndex].licenseeName,
                assignUser: submissionsData.tableData.data[submissionIndex].assignUser,
                submissionStatus: submissionsData.tableData.data[submissionIndex].submissionStatus,
                currentStepName: submissionsData.tableData.data[submissionIndex].currentStep,
              })"
            >
              {{ submissionsData.tableData.data[submissionIndex].submissionId }}
            </span>
          </template>
          <!-- inject formatted date in date column -->
          <template
            v-for="(submissionDateCellSlotName, submissionIndex) in submissionDateCellSlotNames"
            :key="submissionDateCellSlotName"
            #[submissionDateCellSlotName]
          >
            {{ submissionsData.tableData.data[submissionIndex].creationDate ? moment(submissionsData.tableData.data[submissionIndex].creationDate).format('MM/DD/YYYY') : '' }}
          </template>
          <!-- inject formatted date in date column -->
          <template
            v-for="(lastUpdatedCellSlotName, submissionIndex) in lastUpdatedCellSlotNames"
            :key="lastUpdatedCellSlotName"
            #[lastUpdatedCellSlotName]
          >
            {{ submissionsData.tableData.data[submissionIndex].lastmodifiedDate ? moment(submissionsData.tableData.data[submissionIndex].lastmodifiedDate).format('MM/DD/YYYY') : '' }}
          </template>
          <!-- inject formatted date in date column -->
          <template
            v-for="(currentStepDateCellSlotName, submissionIndex) in currentStepDateCellSlotNames"
            :key="currentStepDateCellSlotName"
            #[currentStepDateCellSlotName]
          >
            {{ submissionsData.tableData.data[submissionIndex].stepStartDate ? moment(submissionsData.tableData.data[submissionIndex].stepStartDate).format('MM/DD/YYYY') : '' }}
          </template>
          <!-- inject formatted date in date column -->
          <template
            v-for="(assignedDateCellSlotName, submissionIndex) in assignedDateCellSlotNames"
            :key="assignedDateCellSlotName"
            #[assignedDateCellSlotName]
          >
            {{ submissionsData.tableData.data[submissionIndex].deliveryDate ? moment(submissionsData.tableData.data[submissionIndex].deliveryDate).format('MM/DD/YYYY') : '' }}
          </template>
        </brand-assurance-table>
        <div class="flex justify-end mb-4">
          <display-limit-selector
            v-model="submissionsData.limit"
            class="mr-4"
            :options="limitOptions"
            @click="handlePageUpdate(1)"
          />
          <base-pagination
            :model-value="submissionsData.page"
            :total-pages="submissionsData.pageCount"
            :total-count="submissionsData.totalCount"
            :limit="submissionsData.limit"
            @update:modelValue="handlePageUpdate($event, true)"
          />
        </div>
      </div>
    </div>
    <!-- Save/rename search modal -->
    <base-modal
      v-model="savedSearchModalVisibility"
      :modal-title="`${savedSearchModalMode === 'RENAME' ? 'Rename' : 'Save'} Search`"
      @hidden="handleSavedSearchCancel()"
    >
      <template #modalBody>
        <div class="px-4 py-3">
          <base-input
            v-model="updatedSavedSearchName"
            type="text"
            container-class="w-full flex items-center"
          />
          <div class="text-center my-4">
            <base-button
              class="mr-3"
              variant="btn-primary"
              :disabled="!updatedSavedSearchName"
              :text="savedSearchModalMode === 'RENAME' ? 'Rename' : 'Save'"
              @click="handleSavedSearchUpdate()"
            />
            <base-button
              variant="btn-link"
              text="Cancel"
              @click="handleSavedSearchCancel()"
            />
          </div>
        </div>
      </template>
    </base-modal>
    <!-- confirm delete search modal -->
    <base-modal
      v-model="showDeleteSearchModal"
      modal-title="Confirm Delete Saved Search"
      @hidden="resetDeleteSearchModal()"
    >
      <template #modalBody>
        <div class="px-4 py-3">
          <div class="font-sm text-center mb-2">
            Are you sure to delete <strong>{{ searchToBeDeleted.searchName }}</strong> ?
          </div>
          <div class="text-center mt-2">
            <base-button
              class="mr-3"
              variant="btn-primary"
              text="Yes"
              @click="deleteSavedSearch()"
            />
            <base-button
              variant="btn-link"
              text="No"
              @click="resetDeleteSearchModal()"
            />
          </div>
        </div>
      </template>
    </base-modal>
    <!-- assign task modal -->
    <brand-assurance-assign-tasks-modal
      v-model="showAssignTaskModal"
      :mode="assignTaskModalMode"
      :selected-submission="selectedSubmissions"
      @assignUser="handleAssigningTask($event)"
      @hide="setAssignTaskModalVisibility(false)"
    />
    <!-- submision form modal -->
    <brand-assurance-submission-form-modal
      v-model="showSubmissionFormModal"
      @hide="setSubmissionFormModalVisibility(false)"
      @show="setSubmissionFormModalVisibility(true)"
      @minimize="handleSubmissionFormModalMinimize()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getDistinctArray } from '@/helpers/util.js';
import useBaTable from '@/hooks/baTable.js';
import useAdvanceSearch from '@/hooks/advanceSearch.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { BASE_MOCK_API_ENDPOINT, BRAND_ASSURANCE_ENDPOINT, EXPORT_TO_EXCEL_ENDPOINT } from '@/api/endpointConstants.js';
import useSubmissionFormModal from '@/components/brand-assurance-submission-form/submissionFormModal.js';
import { ADVANCED_SEARCH_MODE, SIMPLE_SEARCH_MODE, GEN_LOB_TYPE_PARAM_KEY, GEN_CAT_TYPE_PARAM_KEY, GEN_TYPE_PARAM_KEY, CAT_TYPE_PARAM_KEY, ASSET_TYPE_PARAM_KEY, MATERIALS_TYPE_PARAM_KEY } from '@/constants/submissions';
import { ADD_ATLEAST_ONE_TABLE_ROW, DELETE_SEARCH_FAILED, DELETE_SEARCH_SUCCESS, ENTER_VALID_TABLE_ENTRIES, NO_SUBMISSIONS_FOUND, RENAME_SEARCH_FAILED, RENAME_SEARCH_SUCCESS, SEARCH_KEYWORD_REQUIRED, SELECT_ATLEAST_ONE_COLUMN, KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED, SAVE_SEARCH_SUCCESS, SAVE_SEARCH_FAILED, SUCCESS, ERROR, WARNING, TASK_ACTION_FAILED, TASK_ACTION_SUCCESS } from '@/constants/alerts';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import moment from 'moment';

export default {
    name: 'BAAdvanceSearch',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BrandAssuranceAssignTasksModal: defineAsyncComponent(() => import('@/components/BrandAssuranceAssignTasksModal.vue')),
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue')),
        BrandAssuranceSubmissionFormModal: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/BrandAssuranceSubmissionFormModal.vue'))
    },

    setup () {
        const store = useStore();
        const { generateExcel, fetchedUserPreference, hasSubmissionAccess } = useSubmissionForm();
        const { addRowColorAndAssignedTo, generateTableSlotName, generateTableSlotNamesByColumnKey } = useBaTable();
        const { updateSubmissionsTabList, resetSubmissionFormModal } = useSubmissionFormModal();
        resetSubmissionFormModal();
        const { simpleSearchFilters, searchMode } = useAdvanceSearch();
        const { notificationsStack } = useToastNotifications();

        const doesUserHaveAssociatedLicensees = computed(() => store.getters['auth/doesUserHaveAssociatedLicensees']);
        const isReadOnlyUser = computed(() => store.getters['auth/isReadOnlyUser']);
        const isUserRestricted = computed(() => doesUserHaveAssociatedLicensees.value || isReadOnlyUser.value);

        // saved search logic
        const savedSearches = computed(() => store.getters['baSubmissions/getSubmissionSavedSearches']);
        const savedSearchesLoading = ref(true);
        const savedSearchFilters = reactive({
            sort: null
        });
        const handleSavedSearchSort = (sortBy) => {
            savedSearchFilters.sort = sortBy;
            fetchSavedSearches();
        };

        const savePreferences = async () => {
            try {
                let params;
                if (fetchedUserPreference?.value) {
                    fetchedUserPreference.value.style.advance_preference = submissionsData.tableData;
                    fetchedUserPreference.value.style.advance_preference.displayCountPreference = submissionsData.limit;
                    params = fetchedUserPreference.value;
                } else {
                    params = {
                        key: currentUser.value,
                        label: 'Preferences',
                        style: {
                            advance_preference: {
                                ...submissionsData.tableData,
                                displayCountPreference: submissionsData.limit
                            }
                        }
                    };
                }

                if (params) {
                    await store.dispatch('users/saveUserPreferences', { params });
                }
            } catch (err) {
                console.error(err);
            }
        };
        const fetchSavedSearches = async () => {
            try {
                savedSearchesLoading.value = true;
                await store.dispatch('baSubmissions/fetchSavedSearch', {
                    params: {
                        ...savedSearchFilters
                    }
                });
                if (fetchedSubmissions.value && fetchedSubmissions.value.data) {
                    updateSubmissionsData();
                }
            } catch (err) {
                console.error(err);
            } finally {
                savedSearchesLoading.value = false;
            }
        };
        onMounted(() => {
            fetchSavedSearches();
        });
        const renameSearch = async () => {
            try {
                await store.dispatch('baSubmissions/updateSavedSearch', {
                    bodyPayload: {
                        ...selectedSavedSearch.value,
                        searchJson: typeof selectedSavedSearch.value.searchJson === 'string' ? selectedSavedSearch.value.searchJson : JSON.stringify(selectedSavedSearch.value.searchJson),
                        searchName: updatedSavedSearchName.value
                    },
                    searchKey: selectedSavedSearch.value.searchKey
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: RENAME_SEARCH_SUCCESS
                });
                updatedSavedSearchName.value = '';
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: RENAME_SEARCH_FAILED
                });
            }
        };
        const loadedSavedSearch = ref(null);
        const loadSavedSearch = (selectedSearch) => {
            searchMode.value = selectedSearch.accessMode;
            loadedSavedSearch.value = {
                ...selectedSearch,
                searchJson: typeof selectedSearch.searchJson === 'string' ? JSON.parse(selectedSearch.searchJson) : selectedSearch.searchJson
            };
            if (searchMode.value === ADVANCED_SEARCH_MODE) {
                advanceSearchFilterTableData.data = transformSearchJsonAsExpectedByAdvanceSearchTable(loadedSavedSearch.value.searchJson);
            } else {
                simpleSearchFilters.value = Object.assign(simpleSearchFilters.value, transformSearchJsonAsExpectedBySimpleSearch(loadedSavedSearch.value.searchJson));
            }
            fetchSubmissions();
        };
        // confirm delete search modal logic
        const showDeleteSearchModal = ref(false);
        const setDeleteSearchModalVisibility = (visibility) => {
            showDeleteSearchModal.value = visibility;
        };
        const searchToBeDeleted = ref(null);
        const resetDeleteSearchModal = () => {
            searchToBeDeleted.value = null;
            setDeleteSearchModalVisibility(false);
        };
        const handleSearchDeletion = (selectedSearch) => {
            searchToBeDeleted.value = selectedSearch;
            setDeleteSearchModalVisibility(true);
        };
        const deleteSearchPendingQueue = ref([]);
        const deleteSavedSearch = async () => {
            const selectedSearchKey = searchToBeDeleted.value.searchKey;
            try {
                deleteSearchPendingQueue.value.push(selectedSearchKey);
                await store.dispatch('baSubmissions/deleteSavedSearch', {
                    searchKey: selectedSearchKey
                });
                // if currently loaded search is same as deleted search, reset search
                if (loadedSavedSearch.value && loadedSavedSearch.value.searchKey === selectedSearchKey) {
                    if (searchMode.value === ADVANCED_SEARCH_MODE) {
                        resetAdvanceSearch();
                    } else {
                        resetSimpleSearch();
                    }
                    clearSubmissions();
                    loadedSavedSearch.value = null;
                }
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: DELETE_SEARCH_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: DELETE_SEARCH_FAILED
                });
            } finally {
                deleteSearchPendingQueue.value = deleteSearchPendingQueue.value.filter(searchKey => searchKey !== selectedSearchKey);
                setDeleteSearchModalVisibility(false);
            }
        };

        // saved search modal logic
        const updatedSavedSearchName = ref('');
        const handleSavedSearchUpdate = async () => {
            if (savedSearchModalMode.value === 'RENAME') {
                renameSearch();
            } else if (savedSearchModalMode.value === 'SAVE') {
                saveSearch();
            }
            setSavedSearchModalVisibility(false);
        };
        const handleSavedSearchCancel = () => {
            updatedSavedSearchName.value = '';
            setSavedSearchModalVisibility(false);
        };
        const selectedSavedSearch = ref(null);
        const handleRenameSearch = (selectedSearch) => {
            selectedSavedSearch.value = selectedSearch;
            updatedSavedSearchName.value = selectedSearch.searchName;
            openSavedSearchModal('RENAME');
        };
        const handleSaveSearch = () => {
            if (isSearchValid.value) {
                if (!loadedSavedSearch.value) {
                    openSavedSearchModal('SAVE');
                } else {
                    saveSearch();
                }
            } else {
                showSearchFiltersValidationError();
            }
        };
        const savedSearchModalMode = ref('');
        const openSavedSearchModal = (mode) => {
            savedSearchModalMode.value = mode;
            setSavedSearchModalVisibility(true);
        };
        const savedSearchModalVisibility = ref(false);
        const setSavedSearchModalVisibility = (visibility) => {
            savedSearchModalVisibility.value = visibility;
        };

        const submissionFilters = reactive({
            columnChooser: []
        });

        const filterColumnChooser = (selectedOption, val) => {
            submissionsData.tableData.columns = actualListOfCols.value.filter(c => selectedOption.includes(c.label));

            let params;
            if (fetchedUserPreference?.value) {
                fetchedUserPreference.value.style.advance_preference.columns = submissionsData.tableData.columns;
                params = fetchedUserPreference.value;
            } else {
                params = {
                    key: currentUser.value,
                    label: 'Preferences',
                    style: {
                        advance_preference: {
                            columns: submissionsData.tableData.columns
                        }
                    }
                };
            }
            store.dispatch('users/saveUserPreferences', { params });
            store.dispatch('userPrefs/updateSubmissionColumns', { submissionColumns: [...submissionsData.tableData.columns] });
        };

        const xlsxColumns = ref(null);
        const xlsxData = ref(null);
        const actualListOfCols = computed(() => store.getters['userPrefs/getActualListOfCols']);

        const submissionColumnChooserOptionsArray = computed(() =>
            actualListOfCols.value.filter(c => !['Submission Id'].includes(c.label)).map(c => c.label)
        );

        onMounted(() => {
            submissionFilters.columnChooser = cachedSubmissionColumns.value.length
                ? cachedSubmissionColumns.value.map(c => c.label) : actualListOfCols.value.map(c => c.label);
        });

        // advance search logic
        const advanceSearchFilterTableData = reactive({
            columns: [
                {
                    label: 'Search Field',
                    key: 'searchField',
                    style: {
                        width: 250
                    }
                },
                {
                    label: 'Search Condition',
                    key: 'searchCondition',
                    style: {
                        width: 250
                    }
                },
                {
                    label: 'Search String',
                    key: 'searchString',
                    style: {
                        width: 250
                    }
                }
            ],
            data: []
        });

        onMounted(() => {
            if (searchMode.value === ADVANCED_SEARCH_MODE) {
                if (advanceSearchFilterTableData.data.length === 0) {
                    advanceSearchFilterTableData.data.push({
                        searchField: '',
                        searchCondition: '',
                        searchString: null
                    });
                }
            } else {
                if (simpleSearchFilters.value.searchFields.length && simpleSearchFilters.value.searchString.length) {
                    applySearch();
                }
            }
        });

        const deleteAdvanceSearchFilter = () => {
            advanceSearchFilterTableData.data = advanceSearchFilterTableData.data.filter(tableObj => !tableObj.selected);
        };
        const isDeleteFilterBtnActive = computed(() => advanceSearchFilterTableData?.data.some(tableObj => tableObj.selected));
        const transformSearchJsonAsExpectedByAdvanceSearchTable = ([...parsedSearchJson]) => {
            if (parsedSearchJson?.length > 0) {
                return parsedSearchJson.map(param => {
                    return {
                        ...param,
                        searchField: (actualListOfCols.value.find(col => col.key === param.searchField) || { label: '' }).label
                    };
                });
            }
            return parsedSearchJson;
        };
        const transformAdvancedSearchPayloadAsExpectedByServer = () => {
            return advanceSearchFilterTableData.data.map(param => {
                return {
                    ...param,
                    searchField: (actualListOfCols.value.find(col => col.label === param.searchField) || { key: '' }).key
                };
            });
        };

        // search fields logic
        const searchFieldOptions = computed(() => {
            const searchFields = actualListOfCols.value.map(col => col.label);
            const filteredDropdownFields = dropdownFields.filter(field => !searchFields.includes(field));

            const tmp = searchFields.concat(filteredDropdownFields);
            return tmp.sort();
        });

        // search conditions logic
        const searchConditionsOptions = ref([]);
        const searchConditionsOptionsOpened = (advanceFilter) => {
            const __field = advanceFilter.searchField || '';
            if (['Submission Id', 'Licensee Name', 'Long Article Description', 'Current Step',
                'Current Stage', 'Submitted By', 'Submission Type', 'Submission Status'].includes(__field)) {
                searchConditionsOptions.value = ['Equals to', 'Contains', 'Starts With', 'Ends With'];
            } else if (['Age at Current Step', 'Age at Current Stage'].includes(__field)) {
                searchConditionsOptions.value = ['Equals to', 'Greater Than', 'Less Than', 'Between', 'Greater Than Equal', 'Less Than Equal'];
            } else if (['Assigned Date', 'Date of Current Step', 'Submission Date', 'Last Updated'].includes(__field)) {
                searchConditionsOptions.value = ['Equals to', 'Greater Than', 'Less Than', 'Between', 'Is Empty', 'Is not Empty'];
            } else if (['Is Safety Needed', 'Safety Documents Included', 'Is Safety Completed', 'Place Safety Hold', 'Place Sample Hold',
                'Escalate Supervisor', 'Rush Submission'].includes(__field)) {
                searchConditionsOptions.value = ['Equals to'];
            } else {
                searchConditionsOptions.value = ['Equals to', 'Contains', 'Starts With', 'Ends With', 'Is Empty', 'Is not Empty'];
            }
        };

        const searchStringOptions = ref([]);

        const fetchSearchStringDropDownValues = async (advanceFilter) => {
            searchStringOptions.value = [];
            if (!advanceFilter || !advanceFilter.searchField) return;
            const __field = advanceFilter.searchField;

            if (['Materials Supplied', 'Asset Type', 'Press Release Category',
                'Line of Business', 'Generic Category', 'Type', 'Category'].includes(__field)) {
                const mdData = await fetchMasterDataDropDownValues(advanceFilter.searchField);
                searchStringOptions.value = mdData ? mdData.map(e => e.name) : [];
            } else if (['Is Safety Needed', 'Safety Documents Included', 'Is Safety Completed',
                'Place Safety Hold', 'Place Sample Hold', 'Escalate Supervisor', 'Rush Submission'].includes(__field)) {
                searchStringOptions.value = ['Y', 'N'];
            } else if (['Current Stage', 'Submitted Item For Review', 'Creative Next Stage', 'Legal Next Stage', 'Next Stage'].includes(__field)) {
                const submissionStages = store.getters['baSubmissions/getSubmissionStages'];
                searchStringOptions.value = submissionStages ? submissionStages.map(stg => stg.stageName) : [];
            } else if (['Submission Type'].includes(__field)) {
                const submissionTypes = store.getters['baSubmissions/getSubmissionTypes'];
                searchStringOptions.value = submissionTypes ? submissionTypes.map(type => type.name) : [];
            } else if (['Submission Status', 'Creative Status', 'Legal Status'].includes(__field)) {
                // WHERE TO FETCH THIS STATUS LIST OR IT SHOULD BE HARDCODED.
                const submissionStatusList = ['Completed', 'Draft', 'Inflight'];
                const submissionStatus = submissionStatusList;
                searchStringOptions.value = submissionStatus;
                // const submissionStatus = store.getters['baSubmissions/getSubmissionStatusList'];
                // searchStringOptions.value = submissionStatus ? submissionStatus.map(type => type.statusName) : [];
            } else if (['Current Step', 'Next Step'].includes(__field)) {
                const submissionStep = store.getters['baSubmissions/getSubmissionSteps'];
                searchStringOptions.value = submissionStep ? submissionStep.map(step => step.stepName) : [];
            }
        };

        const submissionStepsLoading = ref(false);
        onMounted(() => {
            fetchSearchStringDropDownValuesViaApi();
        });

        const fetchMasterDataDropDownValues = async (fieldName) => {
            try {
                let params;
                if (fieldName === 'Materials Supplied') params = { type: MATERIALS_TYPE_PARAM_KEY };
                else if (fieldName === 'Asset Type') params = { type: ASSET_TYPE_PARAM_KEY };
                else if (fieldName === 'Press Release Category') params = { type: CAT_TYPE_PARAM_KEY };
                else if (fieldName === 'Line of Business') params = { type: GEN_LOB_TYPE_PARAM_KEY };
                else if (fieldName === 'Generic Category' || fieldName === 'Category') params = { type: GEN_CAT_TYPE_PARAM_KEY };
                else if (fieldName === 'Type') params = { type: GEN_TYPE_PARAM_KEY };

                const tmp = await store.dispatch('baSubmissions/fetchMasterDataOptions', { params });
                return tmp;
            } catch (err) {
                console.error(err);
            } finally {
                submissionStepsLoading.value = false;
            }
        };

        const fetchSearchStringDropDownValuesViaApi = async () => {
            try {
                await Promise.all([
                    fetchMasterDataDropDownValues(),
                    store.dispatch('baSubmissions/fetchSubmissionStages', {
                        params: {}
                    }),
                    store.dispatch('baSubmissions/fetchSubmissionSteps', {
                        params: {}
                    }),
                    store.dispatch('baSubmissions/fetchSubmissionTypes', {
                        params: {
                            type: 'SUBMISSION'
                        }
                    }),
                    store.dispatch('baSubmissions/fetchSubmissionStatusList', {
                        params: {}
                    })
                ]);
            } catch (err) {
                console.error(err);
            } finally {
                submissionStepsLoading.value = false;
            }
        };

        // search string logic
        const dropdownFields = [
            'Current Stage', 'Current Step', 'Submission Type', 'Submission Status',
            'Category', 'Is Safety Needed', 'Safety Documents Included', 'Is Safety Completed',
            'Place Safety Hold', 'Place Sample Hold', 'Escalate Supervisor', 'Rush Submission',
            'Creative Next Stage', 'Creative Status', 'Legal Next Stage', 'Legal Status', 'Next Stage', 'Next Step',
            'Materials Supplied', 'Asset Type', 'Submitted Item For Review', 'Press Release Category',
            'Line of Business', 'Generic Category', 'Type'
        ];
        const dateFields = ['Assigned Date', 'Date of Current Step', 'Submission Date', 'Last Updated'];
        const dateFieldKeys = ['deliveryDate', 'stepStartDate', 'creationDate', 'lastmodifiedDate'];

        const areSearchStringsDropdown = computed(() => advanceSearchFilterTableData?.data?.length ? advanceSearchFilterTableData.data.map(searchParam => dropdownFields.includes(searchParam.searchField) && searchParam.searchCondition === 'Equals to') : []);
        const areSearchStringsDropdownWithDate = computed(() => advanceSearchFilterTableData?.data?.length ? advanceSearchFilterTableData.data.map(searchParam => dateFields.includes(searchParam.searchField) && searchParam.searchCondition !== 'Between') : []);
        const areSearchStringsDropdownWithDateRange = computed(() => advanceSearchFilterTableData?.data?.length ? advanceSearchFilterTableData.data.map(searchParam => dateFields.includes(searchParam.searchField) && searchParam.searchCondition === 'Between') : []);

        const handleDateRangeSelection = (event, index) => {
            advanceSearchFilterTableData.data[index].searchString = `${moment(event.start).format('MM/DD/YYYY')},${moment(event.end).format('MM/DD/YYYY')}`;
        };

        const handleDateSelection = (event, index) => {
            advanceSearchFilterTableData.data[index].searchString = moment(event).format('MM/DD/YYYY');
        };

        const modifyAdvanceSearch = () => {};
        const resetAdvanceSearch = () => {
            advanceSearchFilterTableData.data = [];
            loadedSavedSearch.value = null;
            clearSubmissions();
        };

        // simple search logic
        const deleteSimpleSearchField = (index) => {
            simpleSearchFilters.value.searchFields.splice(index, 1);
        };
        const resetSimpleSearch = () => {
            simpleSearchFilters.value.searchFields = [];
            simpleSearchFilters.value.searchString = '';
            clearSubmissions();
        };
        const transformSimpleSearchPayloadAsExpectedByServer = () => {
            return simpleSearchFilters.value.searchFields.map(sField => {
                return {
                    searchField: (actualListOfCols.value.find(col => col.label === sField) || { key: '' }).key,
                    searchCondition: 'Contains',
                    searchString: simpleSearchFilters.value.searchString
                };
            });
        };
        const transformSearchJsonAsExpectedBySimpleSearch = ([...parsedSearchJson]) => {
            return {
                searchFields: parsedSearchJson.map(param => (actualListOfCols.value.find(col => col.key === param.searchField) || { label: '' }).label).filter(el => el),
                searchString: parsedSearchJson[0].searchString
            };
        };

        // search action logic
        const switchSearch = (newSearchMode) => {
            searchMode.value = newSearchMode;

            if (searchMode.value === ADVANCED_SEARCH_MODE) resetAdvanceSearch();
            else resetSimpleSearch();
            clearSubmissions();
            loadedSavedSearch.value = null;
        };

        const mapSavePreferenceOnSearch = () => {
            if (fetchedUserPreference?.value && fetchedUserPreference.value?.style && fetchedUserPreference.value?.style?.advance_preference && fetchedUserPreference.value?.style?.advance_preference?.columns) {
                submissionsData.tableData.columns = fetchedUserPreference.value?.style?.advance_preference?.columns;
            }
        };

        const isAdvanceSearchValid = computed(() => advanceSearchFilterTableData.data.length > 0 && advanceSearchFilterTableData.data.filter(searchFilter => searchFilter.searchField?.length > 0 && searchFilter.searchCondition?.length > 0).length === advanceSearchFilterTableData.data.length);
        const isSimpleSearchValid = computed(() => simpleSearchFilters.value.searchFields?.length > 0 && simpleSearchFilters.value.searchString?.length > 0);
        const isSearchValid = computed(() => searchMode.value === ADVANCED_SEARCH_MODE ? isAdvanceSearchValid.value : isSimpleSearchValid.value);



        const applySearch = () => {
            if (isSearchValid.value) {
                const filters = advanceSearchFilterTableData.data.filter(searchFilter => ((!['Is Empty', 'Is not Empty'].includes(searchFilter?.searchCondition) && searchFilter?.searchString?.length > 0) || (['Is Empty', 'Is not Empty'].includes(searchFilter?.searchCondition) && !searchFilter?.searchString?.length > 0)));
                if (filters?.length === advanceSearchFilterTableData.data.length) {
                    mapSavePreferenceOnSearch();
                    fetchSubmissions();
                } else {
                    showSearchFiltersValidationError();
                }
            } else {
                showSearchFiltersValidationError();
            }
        };
        const showSearchFiltersValidationError = () => {
            if (searchMode.value === ADVANCED_SEARCH_MODE) {
                if (advanceSearchFilterTableData?.data?.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: ADD_ATLEAST_ONE_TABLE_ROW
                    });
                } else {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: ENTER_VALID_TABLE_ENTRIES
                    });
                }
            } else {
                if (simpleSearchFilters.value.searchFields.length > 0 && !simpleSearchFilters.value.searchString) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: SEARCH_KEYWORD_REQUIRED
                    });
                } else if (simpleSearchFilters.value.searchString && !simpleSearchFilters.value.searchFields.length) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: SELECT_ATLEAST_ONE_COLUMN
                    });
                } else if (!simpleSearchFilters.value.searchString && !simpleSearchFilters.value.searchFields.length) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED
                    });
                }
            }
        };
        const saveSearchLoading = ref(false);
        const saveSearch = async () => {
            try {
                if (saveSearchLoading.value) {
                    return;
                }
                saveSearchLoading.value = true;
                const searchJson = JSON.stringify(searchMode.value === ADVANCED_SEARCH_MODE ? transformAdvancedSearchPayloadAsExpectedByServer() : transformSimpleSearchPayloadAsExpectedByServer());

                let newSavedSearch;
                if (loadedSavedSearch.value) {
                    newSavedSearch = {
                        ...loadedSavedSearch.value,
                        searchJson
                    };
                    // update existing search
                    await store.dispatch('baSubmissions/updateSavedSearch', {
                        bodyPayload: newSavedSearch,
                        searchKey: loadedSavedSearch.value.searchKey
                    });
                } else {
                    newSavedSearch = {
                        searchName: updatedSavedSearchName.value,
                        searchDesc: '',
                        accessMode: searchMode.value,
                        searchJson
                    };
                    // save new search
                    await store.dispatch('baSubmissions/saveSearch', {
                        bodyPayload: newSavedSearch
                    });
                }
                updatedSavedSearchName.value = '';
                loadedSavedSearch.value = { ...newSavedSearch };
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: SAVE_SEARCH_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: SAVE_SEARCH_FAILED
                });
            } finally {
                saveSearchLoading.value = false;
            }
        };

        // search result table logic
        const cachedSubmissionColumns = computed(() => store.getters['userPrefs/getSubmissionColumns']);
        const fetchedSubmissions = computed(() => store.getters['baSubmissions/getSubmissionsList']);

        const tableColArray = cachedSubmissionColumns.value.length ? cachedSubmissionColumns.value : actualListOfCols.value;

        const submissionsData = reactive({
            tableData: {
                columns: [...tableColArray],
                data: []
            },
            page: 1,
            limit: fetchedUserPreference?.value?.style?.advance_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.advance_preference?.displayCountPreference : 50,
            pageCount: fetchedSubmissions.value ? fetchedSubmissions.value.pageCount : 0,
            totalCount: fetchedSubmissions.value ? fetchedSubmissions.value.totalCount : 0
        });

        const selectedSubmissions = computed(() => submissionsData.tableData && submissionsData.tableData.data.length && submissionsData.tableData.data.filter(tableObj => tableObj.selected));
        const submissionIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'submissionId', 1));
        const submissionDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'creationDate', 1));
        const lastUpdatedCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'lastmodifiedDate', 1));
        const currentStepDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'stepStartDate', 1));
        const assignedDateCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(submissionsData.tableData, 'deliveryDate', 1));
        const updateSubmissionsData = () => {
            submissionsData.tableData.data = addRowColorAndAssignedTo(fetchedSubmissions.value.data);
            submissionsData.pageCount = fetchedSubmissions.value.pageCount;
            submissionsData.totalCount = fetchedSubmissions.value.totalCount;
            if (selectedSubmissions.value.length > 0) {
                deSelectSubmissionTable();
            }
            if (submissionsData.tableData.data.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_SUBMISSIONS_FOUND
                });
            }
        };
        const deSelectSubmissionTable = () => {
            submissionsData.tableData.data.forEach(tableObj => {
                tableObj.selected = false;
            });
        };

        const isSubmissionTableLoading = ref(false);
        const fetchSubmissions = async (shouldEntireTableReload = true) => {
            try {
                if (shouldEntireTableReload) {
                    isSubmissionTableLoading.value = true;
                }
                const bodyPayload = {
                    page: submissionsData.page,
                    limit: fetchedUserPreference?.value?.style?.advance_preference?.displayCountPreference ? fetchedUserPreference?.value?.style?.advance_preference?.displayCountPreference : 50,
                    ...sortingFilters
                };
                bodyPayload.advancedSearch = searchMode.value === ADVANCED_SEARCH_MODE ? transformAdvancedSearchPayloadAsExpectedByServer() : transformSimpleSearchPayloadAsExpectedByServer();
                bodyPayload.advancedSearch.forEach(e => {
                    if (dateFieldKeys.includes(e.searchField) && e.searchCondition === 'Equals to') {
                        const raw = moment(`${e.searchString.trim()}`, 'MM/DD/YYYY');
                        const tmp1 = raw.format('MM/DD/YYYY');
                        const tmp2 = moment(raw + 86410000).format('MM/DD/YYYY');
                        e.searchString = `${tmp1}|DATE-EQUAL|${tmp2}`;
                    } else e.searchString = `${e.searchString.trim()}`;
                });

                await store.dispatch('baSubmissions/advanceSearch', { bodyPayload });
                if (fetchedSubmissions.value && fetchedSubmissions.value.data) {
                    updateSubmissionsData();
                }

                if (fetchedUserPreference?.value && fetchedUserPreference.value?.style && fetchedUserPreference.value?.style?.advance_preference && fetchedUserPreference.value?.style?.advance_preference?.columns) {
                    submissionsData.tableData.columns = fetchedUserPreference.value?.style?.advance_preference?.columns;
                }
            } catch (err) {
                console.error(err);
            } finally {
                isSubmissionTableLoading.value = false;
            }
        };

        const limitOptions = ref([
            50,
            100,
            200
        ]);
        const handlePageUpdate = async (newPage, paginator = false) => {
            submissionsData.page = newPage;
            if (!paginator) await savePreferences();
            await fetchSubmissions();
        };

        const clearSubmissions = () => {
            store.dispatch('baSubmissions/clearSubmissions');
            submissionsData.tableData.data = [];
            submissionsData.page = 1;
            submissionsData.pageCount = 0;
            submissionsData.totalCount = 0;
        };
        onMounted(() => {
            clearSubmissions();
        });

        // submissions table sort logic
        const isSubmissionTableSorting = ref(false);
        const sortingFilters = reactive({
            order: []
        });
        const sortSubmissionTable = async (columnPayload) => {
            try {
                isSubmissionTableSorting.value = true;
                if (columnPayload.sortOrder) {
                    sortingFilters.sort = `${columnPayload.columnKey},${columnPayload.sortOrder}`;
                } else {
                    sortingFilters.sort = '';
                }
                await fetchSubmissions(false);
            } catch (err) {
                console.error(err);
            } finally {
                // update sorting order in UI
                submissionsData.tableData.columns[columnPayload.columnIndex].sorted = columnPayload.sortOrder;
                isSubmissionTableSorting.value = false;
            }
        };

        // refersh submission table logic
        const currentUser = computed(() => store.getters['auth/getUserId']);
        const handleRefresh = () => {
            fetchSubmissions();
        };

        // open submission details logic
        const openTaskButtonActive = computed(() => selectedSubmissions.value.length > 0 && selectedSubmissions.value.length <= 50);
        const openSubmissions = (tabPayload) => {
            let submissionsToBeOpened = null;
            if (tabPayload) {
                submissionsToBeOpened = [tabPayload];
            } else {
                submissionsToBeOpened = selectedSubmissions.value.map(submission => {
                    return {
                        submissionId: submission.submissionId,
                        licenseeName: submission.licenseeName,
                        assignUser: submission.assignUser,
                        submissionStatus: submission.submissionStatus,
                        currentStepName: submission.currentStepName
                    };
                });
            }
            updateSubmissionsTabList(submissionsToBeOpened);
            setSubmissionFormModalVisibility(true);
            if (selectedSubmissions.value.length > 0) {
                deSelectSubmissionTable();
            }
        };
        const showSubmissionFormModal = ref(false);
        const setSubmissionFormModalVisibility = (visibility) => {
            showSubmissionFormModal.value = visibility;
        };
        const handleSubmissionFormModalMinimize = () => {
            showSubmissionFormModal.value = false;
        };

        // submission task handler logic
        const claimTaskButtonActive = computed(() =>
            selectedSubmissions.value.length > 0 &&
            !selectedSubmissions.value.some(s => !hasSubmissionAccess(s.currentStep)) &&
            selectedSubmissions.value.filter(submission =>
                !submission.assignUser &&
                ['Inflight'].includes(submission.submissionStatus) &&
                (!isUserRestricted.value || submission.currentStep === 'Licensee'))
                .length === selectedSubmissions.value.length
        );
        const unclaimTaskButtonActive = computed(() =>
            selectedSubmissions.value.length > 0 &&
            !selectedSubmissions.value.some(s => !hasSubmissionAccess(s.currentStep)) &&
            selectedSubmissions.value.filter(submission =>
                submission.assignUser &&
                ['Inflight'].includes(submission.submissionStatus) &&
                (!isUserRestricted.value || submission.currentStep === 'Licensee'))
                .length === selectedSubmissions.value.length
        );
        const assignButtonActive = computed(() => claimTaskButtonActive.value);
        const reAssignButtonActive = computed(() => unclaimTaskButtonActive.value);

        const isTaskLoading = ref(false);
        const updatedSubmissionsTasks = computed(() => store.getters['baSubmissions/getSubmissionTask']);
        const taskActions = async (action, newAssignee) => {
            try {
                if (isTaskLoading.value) {
                    return;
                }
                isTaskLoading.value = true;

                const submissions = selectedSubmissions.value.map(sub => {
                    return { submissionId: sub.submissionId, taskId: sub.taskId, currentStepName: sub.currentStep };
                });

                const bodyPayload = {
                    submissions,
                    action
                };

                if (action !== 'unclaim') {
                    bodyPayload.assignUser = currentUser.value;
                }

                if (action === 'assign' || action === 'reassign') {
                    bodyPayload.assignUser = newAssignee;
                }

                try {
                  await store.dispatch('baSubmissions/submissionTasks', { bodyPayload });
                } catch (claimErr) {
                  return notificationsStack.value.push({
                    type: ERROR,
                    message: `Cannot ${action} one or more submissions. Please refresh your search and try again.`
                  })
                }

                await store.dispatch('baSubmissions/updateAssigneeInSubmissionsList', { newSubmissions: updatedSubmissionsTasks.value });
                updateSubmissionsData();
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: TASK_ACTION_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: TASK_ACTION_FAILED
                });
            } finally {
                isTaskLoading.value = false;
            }
        };

        const assignTaskModalMode = ref('');
        const showAssignTaskModal = ref(false);
        const setAssignTaskModalVisibility = (modalVisibility) => {
            showAssignTaskModal.value = modalVisibility;
        };
        const handleAssigningTask = (newAssignee) => {
            taskActions(assignTaskModalMode.value, newAssignee);
        };

        const openAssignTaskModal = (mode) => {
            assignTaskModalMode.value = mode;
            setAssignTaskModalVisibility(true);
        };

        // export to excel logic
        const excelUrl = `${BASE_MOCK_API_ENDPOINT}${BRAND_ASSURANCE_ENDPOINT}${EXPORT_TO_EXCEL_ENDPOINT}`;
        const downloadExcelFile = () => {
            window.location.href = excelUrl;
        };

        // print receipt logic
        const isPrintingReceipts = ref(false);
        const printReceiptsButtonActive = computed(() => selectedSubmissions.value.length > 0 && !isPrintingReceipts.value);
        const printReceipts = async () => {
            try {
                if (isPrintingReceipts.value) return;
                isPrintingReceipts.value = true;
                const promises = await store.dispatch('baSubmissions/downloadSubmissionReceipt', {
                    submissionIds: selectedSubmissions.value.map(s => s.submissionId)
                });
                const resp = await Promise.all(promises);
                resp.map(r => {
                    if (!r.data || !r.data.data || r.data.message !== 'Receipt downloaded successfully') {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: `Invalid response for submission receipt [ ${r.config.url.split('/')[1]} ]`
                        });
                    } else {
                        const windowOpened = window.open(r.data.data, '_blank');
                        if (!windowOpened) {
                            notificationsStack.value.push({
                                type: ERROR,
                                message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                            });
                        }
                    }
                });
            } catch (e) {
                console.log(e);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while downloading receipt'
                });
            } finally {
                isPrintingReceipts.value = false;
            }
        };

        watch(
            () => showSubmissionFormModal.value,
            () => {
                if (!showSubmissionFormModal.value) {
                    handleRefresh();
                }
            }
        );

        const addAdvanceSearchFilter = () => {
            advanceSearchFilterTableData.data.push({
                searchField: '',
                searchCondition: '',
                searchString: ''
            });
        };

        const searchedSubmissionId = computed(() => store.getters['baSubmissions/getSearchedSubmissionId']);
        watch(
            () => searchedSubmissionId.value,
            () => {
                advanceSearchFilterTableData.data = [];
                if (advanceSearchFilterTableData?.data.length === 0) addAdvanceSearchFilter();
                if (searchedSubmissionId.value !== null && advanceSearchFilterTableData?.data[0]) {
                    advanceSearchFilterTableData.data[0].searchField = searchedSubmissionId.value !== null ? 'Submission Id' : '';
                    advanceSearchFilterTableData.data[0].searchCondition = searchedSubmissionId.value !== null ? 'Contains' : '';
                    advanceSearchFilterTableData.data[0].searchString = searchedSubmissionId.value;
                    applySearch();
                }
            }, { deep: true, immediate: true }
        );
        /* excel logic below */
        const exportExcel = async () => {
            xlsxColumns.value = actualListOfCols.value;
            if (selectedSubmissions.value && selectedSubmissions.value.length > 0) {
                xlsxData.value = selectedSubmissions.value;
            } else {
                await fetchDataForExcelExport();
            }
            generateExcel('AdvanceSearchSubmission_Export-', xlsxColumns, xlsxData);
        };

        const fetchDataForExcelExport = async () => {
            const bodyPayload = {
                page: 1,
                limit: 2000,
                ...sortingFilters
            };
            bodyPayload.advancedSearch = searchMode.value === ADVANCED_SEARCH_MODE ? transformAdvancedSearchPayloadAsExpectedByServer() : transformSimpleSearchPayloadAsExpectedByServer();
            bodyPayload.advancedSearch.forEach(e => {
                if (dateFieldKeys.includes(e.searchField) && e.searchCondition === 'Equals to') {
                    const raw = moment(`${e.searchString.trim()}`, 'MM/DD/YYYY');
                    const tmp1 = raw.format('MM/DD/YYYY');
                    const tmp2 = moment(raw + 86410000).format('MM/DD/YYYY');
                    e.searchString = `${tmp1}|DATE-EQUAL|${tmp2}`;
                } else e.searchString = `${e.searchString.trim()}`;
            });

            const res = await store.dispatch('baSubmissions/advanceSearchExportExcel', { bodyPayload });
            if (res.totalCount > 2000) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Limit reached. Maximum of 2000 records can only be exported. Please change  filters and try again.'
                });
                return '';
            } else {
                xlsxData.value = res.data;
            }
        };

        const handleSearchField = (newSearchString, fullArr, rIndex) => {
            console.log('newSearchString, rIndex: ', newSearchString, fullArr, rIndex);
            fullArr[rIndex].searchField = newSearchString;
            fullArr[rIndex].searchCondition = '';
            fullArr[rIndex].searchString = '';
        };

        const handleSearchCondition = (newSearchCondition, fullArr, rIndex) => {
            console.log('newSearchCondition, rIndex: ', newSearchCondition, fullArr, rIndex);
            fullArr[rIndex].searchCondition = newSearchCondition;
            fullArr[rIndex].searchString = '';
        };

        const colResized = () => {
            savePreferences();
        };

        return {
            handleSearchField,
            handleSearchCondition,
            generateTableSlotName,
            ADVANCED_SEARCH_MODE,
            SIMPLE_SEARCH_MODE,
            // saved search
            savedSearches,
            handleSavedSearchSort,
            // saved search modal
            handleSavedSearchUpdate,
            handleSavedSearchCancel,
            updatedSavedSearchName,
            savedSearchModalMode,
            openSavedSearchModal,
            savedSearchModalVisibility,
            setSavedSearchModalVisibility,
            handleRenameSearch,
            handleSaveSearch,
            loadSavedSearch,
            loadedSavedSearch,
            // confirm delete search modal
            showDeleteSearchModal,
            resetDeleteSearchModal,
            handleSearchDeletion,
            deleteSavedSearch,
            searchToBeDeleted,
            // advance search
            advanceSearchFilterTableData,
            addAdvanceSearchFilter,
            deleteAdvanceSearchFilter,
            isDeleteFilterBtnActive,
            searchFieldOptions,
            searchConditionsOptions,
            areSearchStringsDropdown,
            modifyAdvanceSearch,
            resetAdvanceSearch,
            // simple search
            simpleSearchFilters,
            deleteSimpleSearchField,
            resetSimpleSearch,
            // search action
            searchMode,
            switchSearch,
            isSearchValid,
            applySearch,
            saveSearch,
            saveSearchLoading,
            // search result table
            isSubmissionTableLoading,
            submissionsData,
            submissionIdCellSlotNames,
            submissionDateCellSlotNames,
            lastUpdatedCellSlotNames,
            currentStepDateCellSlotNames,
            assignedDateCellSlotNames,
            limitOptions,
            handlePageUpdate,
            clearSubmissions,
            // submission table refresh
            handleRefresh,
            // submission task handler
            openTaskButtonActive,
            openSubmissions,
            showSubmissionFormModal,
            setSubmissionFormModalVisibility,
            handleSubmissionFormModalMinimize,
            claimTaskButtonActive,
            isTaskLoading,
            taskActions,
            unclaimTaskButtonActive,
            assignButtonActive,
            showAssignTaskModal,
            assignTaskModalMode,
            setAssignTaskModalVisibility,
            openAssignTaskModal,
            handleAssigningTask,
            reAssignButtonActive,
            // export to excel
            downloadExcelFile,
            // print receipt
            printReceiptsButtonActive,
            printReceipts,
            // submissions table sort
            isSubmissionTableSorting,
            sortSubmissionTable,
            savePreferences,
            fetchSearchStringDropDownValues,
            searchStringOptions,
            searchConditionsOptionsOpened,
            getDistinctArray,
            submissionFilters,
            filterColumnChooser,
            submissionColumnChooserOptionsArray,
            moment,
            isUserRestricted,
            isPrintingReceipts,
            xlsxColumns,
            xlsxData,
            selectedSubmissions,
            areSearchStringsDropdownWithDateRange,
            areSearchStringsDropdownWithDate,
            handleDateSelection,
            handleDateRangeSelection,
            exportExcel,
            colResized
            // advanceSearchFilter
            // submissionDataPreference
        };
    }
};

</script>
<style>
.advance-search-dropdown td:nth-child(2),
.advance-search-dropdown td:nth-child(3),
.advance-search-dropdown td:nth-child(4) {
  /* max-width: 500px !important; */
  width: 28%;
}
</style>
